import React, { useState, useLayoutEffect } from 'react'
import { Flex, Box } from 'reflexbox'
import * as styles from './LoadingScreen.module.scss'
import classnames from 'classnames'
const LoadingScreen = () => {
  const [isLoaded, setLoaded] = useState(false)
  const [isHidden, setHidden] = useState(false)

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoaded(true)
      setTimeout(() => {
        setHidden(true)
      }, 300)
    }, 1500)
  }, [])

  return isHidden ? null : (
    <Flex
      justifyContent="center"
      alignItems="center"
      id="loader"
      className={classnames(
        styles.wrapper,
        isLoaded ? styles.loaded : '',
        isHidden ? styles.hidden : ''
      )}
    >
      <Box className={styles.icon}>
        <img src="/images/icon.svg" />
      </Box>
    </Flex>
  )
}

export default LoadingScreen
