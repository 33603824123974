import React from 'react'
import { Box, Flex } from 'reflexbox'
import { Container } from 'src/components/UI/Grid/Grid'
import classnames from 'classnames'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import * as styles from './Footer.module.scss'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import SbEditable from 'storyblok-react'
const Footer = ({ blok, preview }: { blok: any; preview?: boolean }) => {
  const { links } = blok || {}

  return (
    <SbEditable content={blok}>
      <Box height={preview ? '96px' : 0} />
      <Container>
        <Box className={styles.border} />
      </Container>
      <Container as="footer" className={styles.wrapper}>
        <Flex
          paddingY={6}
          justifyContent="space-between"
          className={styles.links}
        >
          <Flex>
            {links?.length > 0 &&
              links.map((link: any, index: number) => {
                const { url, title } = getLinkAttributes(link) || {}
                return (
                  <Box
                    key={`footer-link-${index}`}
                    marginRight={index !== links.length - 1 ? 6 : 0}
                  >
                    <LinkComponent to={url} title={title}>
                      {title}
                    </LinkComponent>
                  </Box>
                )
              })}
          </Flex>
          <Flex>
            <Box>
              <LinkComponent to="https://www.polestar.com" title="Polestar">
                <img src="/images/polestar.svg" />
              </LinkComponent>
            </Box>
            <Box marginLeft={6}>
              <LinkComponent
                to="https://www.fkpscorpio.se/"
                title="FKP Scorpio"
              >
                <img src="/images/fkp.svg" />
              </LinkComponent>
            </Box>
          </Flex>
        </Flex>
      </Container>
    </SbEditable>
  )
}

export default Footer
