export const getLinkAttributes = (link: any) => {
    const url = link?.slug?.cached_url
      ? link.slug.cached_url
      : link?.slug?.email
      ? `mailto:${link.slug.email}`
      : ''
    const urlFormatted = link?.slug?.anchor ? `${url}#${link.slug.anchor}` : url
    const title = link?.title ? link.title : ''
  
    return {
      url: urlFormatted,
      title: title,
    }
  }
  