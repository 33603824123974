import React, { useEffect, useState, useLayoutEffect } from 'react'
// import Helmet from 'react-helmet'
// import browserLang from 'browser-lang'
// import { navigate } from 'gatsby'
import { Props } from './types'
import * as styles from './Layout.module.scss'
import { IntlProvider } from 'react-intl'
import LoadingScreen from 'src/components/LoadingScreen/LoadingScreen'
// import Header from 'src/components/Header/Header'
import { Container } from '../UI/Grid/Grid'
import { Flex, Box } from 'reflexbox'
import Footer from 'src/storyblok/Global/Footer/Footer'
// import Information from 'src/storyblok/Global/Information/Information'
import axios from 'axios'

export default (props: Props): JSX.Element => {
  const { children, pageContext, path } = props
  const [userChecked, setUserChecked] = useState(false)
  const [loadSite, setLoadSite] = useState(false)

  const { globalData, language = 'en', messages = {} }: any = pageContext
    ? pageContext
    : {}
  const { footer }: any = globalData ? globalData : {}

  const checkUser = async () => {
    // const userAgent = window.navigator.userAgent

    // setUserChecked(true)
    // setLoadSite(true)

    // TEST
    // CLIENT CHECK
    // if (
    //   userAgent &&
    //   userAgent.includes('Polestar') &&
    //   userAgent.includes('Android') &&
    //   userAgent.includes('Chrome')
    // ) {
    //   setUserChecked(true)
    //   setLoadSite(true)
    // } else {
    //   setUserChecked(true)
    // }

    // SERVER CHECK
    try {
      const response: any = await axios.get('/api/testUser')
      setUserChecked(true)
      if (response && response.status === 200) {
        setLoadSite(true)
      }
    } catch (error) {
      console.log('ERROR', error)
      setUserChecked(true)
    }
  }

  useEffect(() => {
    checkUser()
  }, [])

  useEffect(() => {
    // const storageKey = 'is-redirected'
    // const isRedirected = localStorage.getItem(storageKey)
    // if (!isRedirected) {
    //   localStorage.setItem(storageKey, 'true')
    //   const userLanguage = browserLang({
    //     languages: ['sv', 'en'],
    //     fallback: 'sv',
    //   })
    //   if (language !== userLanguage) {
    //     if (userLanguage === 'sv') {
    //       navigate(`/sv${path}`)
    //     } else {
    //       if (path === '/sv') {
    //         navigate(path.replace('/sv', '/'))
    //       } else {
    //         navigate(path.replace('sv/', ''))
    //       }
    //     }
    //   }
    // }
  }, [])

  return (
    <IntlProvider locale={language} messages={messages}>
      <div style={{ overflow: 'hidden' }}>
        <LoadingScreen />
        {/* <Header /> */}
        {userChecked ? (
          loadSite ? (
            <React.Fragment>
              {/* {information?.content && (
                <Information blok={information.content} />
              )} */}
              {children}
            </React.Fragment>
          ) : (
            <Container>
              <Flex
                className={styles.loggedOut}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  marginBottom={8}
                  as="h1"
                  width={[1, null, null, 8 / 12]}
                  className={styles.title}
                >
                  <Box marginBottom={[4, null, null, 8]}>
                    {messages?.noPolestar}
                  </Box>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: messages?.enjoyFestival,
                    }}
                  />
                </Box>
              </Flex>
            </Container>
          )
        ) : (
          <Container>
            <Flex
              className={styles.loggedOut}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                marginBottom={8}
                as="h1"
                width={[1, null, null, 4 / 12]}
                className={styles.title}
              >
                {messages?.authenticating}
              </Box>
            </Flex>
          </Container>
        )}
        {footer?.content && <Footer blok={footer.content} />}
      </div>
    </IntlProvider>
  )
}
