import React, { useEffect } from 'react'
import { Location } from '@reach/router'
import { ThemeProvider } from 'emotion-theming'
import { RecoilRoot } from 'recoil'
// import 'focus-visible'

import { Props } from './types'

const theme = {
  breakpoints: ['374px', '767px', '1024px', '1099px', '1920px'],
  space: [...Array(60)].map((_, i) => i * 4),
}
export default (props: Props): JSX.Element => {
  const { element } = props

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <Location>
          {({ location }) => {
            return element
          }}
        </Location>
      </ThemeProvider>
    </RecoilRoot>
  )
}
