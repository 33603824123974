import * as React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { Props } from './types'

const getHash = (url: string) => {
  const index = url.indexOf('#')
  let hash = ''
  let urlTemp = url
  if (index !== -1) {
    hash = `#${url.substring(index + 1)}`
    urlTemp = url.substring(0, index)
  }
  return { url: urlTemp, hash: hash }
}

const LinkComponent = ({
  to,
  children,
  onClick,
  onAnimationEnd,
  className,
  title,
  onMouseEnter,
  onMouseLeave,
  style,
  ariaLabel,
  target = '_blank',
}: Props): JSX.Element => {
  if (!to) {
    return <React.Fragment>{children}</React.Fragment>
  }
  let { url } = getHash(to)
  const { hash } = getHash(to)

  const isDownload = url.substring(0, 9) === 'data:text'
  const isExternal =
    url.substring(0, 4) === 'http' ||
    url.substring(0, 7) === 'mailto:' ||
    url.substring(0, 6) === 'tel:' ||
    isDownload

  // Check for missing "/" in relative urls
  if (!isExternal && !url.startsWith('/')) {
    url = `/${url}`
  }

  // Remove home from slug
  if (!isExternal && url === '/home') {
    url = `/`
  }

  if (!isExternal && url === '/en/home') {
    url = `/en`
  }

  const href = `${url}${hash}`

  return isExternal ? (
    <OutboundLink
      className={className}
      href={href}
      onClick={onClick}
      onAnimationEnd={onAnimationEnd}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : ''}
      title={title}
      style={style}
      download={isDownload}
    >
      {children}
    </OutboundLink>
  ) : href ? (
    <Link
      style={style}
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      title={title}
      to={href}
      aria-label={ariaLabel}
    >
      {children}
    </Link>
  ) : (
    <div
      style={style}
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </div>
  )
}

export default LinkComponent
